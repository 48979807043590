<script lang="ts" setup>
import type { CardSelectedProps } from '../type';
import IconSource from './IconSource.vue';
import ContentProductSelected from './ContentProductSelected.vue';
import { computed } from 'vue';

const props = defineProps<CardSelectedProps>();

const isNoImage = computed(() => props.isNoAssignedCollections || !props?.itemDisplay?.image);
const isShowSubTitle = computed(() =>
  props.isNoAssignedCollections ? !props.isNoAssignedCollections : !!props?.itemDisplay?.id,
);
</script>
<template>
  <div :class="classContainer">
    <img
      v-if="isNoImage"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/collection-thumbnail.png"
      alt="collection feature img" />
    <img
      v-else
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      :src="itemDisplay?.image"
      alt="collection feature img" />
    <ContentProductSelected
      :is-show-sub-title="isShowSubTitle"
      :title="title"
      :sub-title="subTitle"
      lineClampClass="line-clamp-1" />
    <IconSource :is-loading="isLoading" @refresh="refresh" :isCollectionType="true" />
  </div>
</template>
