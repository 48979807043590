export const areEqualArrays = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  for (const item of arr1) {
    if (!arr2.includes(item)) {
      return false;
    }
  }
  return true;
};

export const tooltipIconStatusContent = (status?: string) => {
  if (!status) return '';
  return status === 'draft' ? 'Draft product' : 'Archived product';
};
