import type { Maybe, Scalars } from './graphql';

export type Product = {
  id: string;
  title?: string;
  cursor?: string;
  image?: string;
  options?: ProductOption[];
  status?: string;
  handle?: string;
};
export type Platform = 'BIG' | 'SHOPIFY' | 'WOO';

export type ProductOptionValue = {
  baseID?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  sortOrder?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type ProductOption = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  optionType?: Maybe<Scalars['String']>;
  values: Array<ProductOptionValue>;
};
export type ProductVariant = Maybe<{
  barcode?: Maybe<Scalars['String']>;
  baseID?: Maybe<Scalars['String']>;
  costPrice?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inventoryPolicy?: Maybe<Scalars['String']>;
  inventoryQuantity?: Maybe<Scalars['Int']>;
  inventoryStatus?: Maybe<Scalars['String']>;
  isDigital?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Float']>;
  lowInventoryAmount?: Maybe<Scalars['Int']>;
  manageInventory?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Media>;
  mediaId: Scalars['ID'];
  metafield?: Maybe<Metafield>;
  platform?: Maybe<Platform>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  salePrice?: Maybe<Scalars['Float']>;
  selectedOptions: Array<SelectedOption>;
  sku?: Maybe<Scalars['String']>;
  soldIndividually?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
}>;
export type Media = {
  alt?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  deletedAt?: Maybe<Scalars['Time']>;
  embeddedUrl?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  host?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  previewImage?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  sources?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  width?: Maybe<Scalars['Float']>;
};
export type Metafield = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  namespace: Scalars['String'];
  objectId: Scalars['Uint'];
  objectType: ObjectType;
  value?: Maybe<Scalars['String']>;
};
export type SelectedOption = {
  name?: Maybe<Scalars['String']>;
  optionType?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};
export type ObjectType = 'ARTICLE' | 'BLOG' | 'COLLECTION' | 'PAGE' | 'PRODUCT' | 'PRODUCT_VARIANT' | 'SHOP';

export type ScreenUnit =
  | 'px'
  | 'cm'
  | 'mm'
  | 'in'
  | 'pt'
  | 'pc'
  | 'em'
  | 'rem'
  | 'ex'
  | 'ch'
  | 'vw'
  | 'vh'
  | 'vmin'
  | 'vmax'
  | 's'
  | '%';

export type MoneyUnit = '$' | '%';

export type OptionItem = {
  label?: string;
  value?: string;
  icon?: string;
  baseIcon?: string;
  type?: string;
  tooltip?: string;
  viewBox?: string;
  width?: string;
  height?: string;
  des?: string;
};

export type ImageUploadType = {
  filePath?: string;
  fileName?: string;
  src?: string;
  backupFileKey?: string;
  backupFilePath?: string;
  width?: number;
  height?: number;
  storage?: 'THEME' | 'FILE_CONTENT';
  isShow?: boolean;
  mimeType?: string;
};

export enum ThemePageType {
  ARTICLE = 'ARTICLE',
  BLOG = 'BLOG',
  COLLECTION = 'COLLECTION',
  GP_ARTICLE = 'GP_ARTICLE',
  GP_BLOG = 'GP_BLOG',
  GP_COLLECTION = 'GP_COLLECTION',
  GP_FUNNEL_PAGE = 'GP_FUNNEL_PAGE',
  GP_INDEX = 'GP_INDEX',
  GP_PRODUCT = 'GP_PRODUCT',
  GP_STATIC = 'GP_STATIC',
  POST_PURCHASE = 'POST_PURCHASE',
  PRODUCT = 'PRODUCT',
  STATIC = 'STATIC',
}

export type BlogList = {
  id: string;
  title?: string;
  image?: string;
};
