<script lang="ts" setup>
import { GTooltip } from '@gem/uikit-v2';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import ID from '../utils/id';

interface Props {
  id?: string;
  values?: string[];
  deals?: any;
  embedAppUrl?: string;
  isLoading?: boolean;
  discountId?: string;
  objectBaseId?: string;
  isProductDiscountLoading?: boolean;
  isProductPublished?: boolean;
}

const INACTIVE_BUNDLE_ICON = `<svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H24.5C28.9183 0 32.5 3.58172 32.5 8V12C32.5 16.4183 28.9183 20 24.5 20H8.5C4.08172 20 0.5 16.4183 0.5 12V8Z" fill="#FDB913" fill-opacity="0.2"/>
<path d="M18.7245 8.62422C18.9588 8.3899 18.9588 8.01 18.7245 7.77569C18.4901 7.54137 18.1102 7.54137 17.8759 7.77569L14.2759 11.3757C14.0416 11.61 14.0416 11.9899 14.2759 12.2242C14.5102 12.4585 14.8901 12.4585 15.1245 12.2242L18.7245 8.62422Z" fill="#FED571"/>
<path d="M15.7002 8.39995C15.7002 8.84178 15.342 9.19995 14.9002 9.19995C14.4584 9.19995 14.1002 8.84178 14.1002 8.39995C14.1002 7.95812 14.4584 7.59995 14.9002 7.59995C15.342 7.59995 15.7002 7.95812 15.7002 8.39995Z" fill="#FED571"/>
<path d="M18.1002 12.4C18.542 12.4 18.9002 12.0418 18.9002 11.6C18.9002 11.1581 18.542 10.8 18.1002 10.8C17.6584 10.8 17.3002 11.1581 17.3002 11.6C17.3002 12.0418 17.6584 12.4 18.1002 12.4Z" fill="#FED571"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.1757 4.81148C17.518 3.37892 15.4824 3.37892 14.8247 4.81148C14.6843 5.11742 14.3318 5.26344 14.0161 5.14641C12.5381 4.59848 11.0987 6.03788 11.6467 7.51588C11.7637 7.83153 11.6177 8.18404 11.3117 8.32449C9.87917 8.98215 9.87917 11.0178 11.3117 11.6754C11.6177 11.8159 11.7637 12.1684 11.6467 12.484C11.0987 13.962 12.5381 15.4014 14.0161 14.8535C14.3318 14.7365 14.6843 14.8825 14.8247 15.1884C15.4824 16.621 17.518 16.621 18.1757 15.1884C18.3161 14.8825 18.6686 14.7365 18.9843 14.8535C20.4623 15.4014 21.9017 13.962 21.3537 12.484C21.2367 12.1684 21.3827 11.8159 21.6887 11.6754C23.1212 11.0178 23.1212 8.98215 21.6887 8.32449C21.3827 8.18404 21.2367 7.83153 21.3537 7.51588C21.9017 6.03788 20.4623 4.59848 18.9843 5.14641C18.6686 5.26344 18.3161 5.11742 18.1757 4.81148ZM15.9153 5.31213C16.1449 4.81204 16.8555 4.81204 17.0851 5.31213C17.4874 6.18854 18.4972 6.6068 19.4014 6.27158C19.9174 6.0803 20.4199 6.58279 20.2286 7.09875C19.8934 8.00296 20.3116 9.01273 21.188 9.41506C21.6881 9.64465 21.6881 10.3553 21.188 10.5849C20.3116 10.9872 19.8934 11.997 20.2286 12.9012C20.4199 13.4171 19.9174 13.9196 19.4014 13.7283C18.4972 13.3931 17.4874 13.8114 17.0851 14.6878C16.8555 15.1879 16.1449 15.1879 15.9153 14.6878C15.513 13.8114 14.5032 13.3931 13.599 13.7283C13.083 13.9196 12.5805 13.4171 12.7718 12.9012C13.107 11.997 12.6888 10.9872 11.8124 10.5849C11.3123 10.3553 11.3123 9.64465 11.8124 9.41506C12.6888 9.01273 13.107 8.00296 12.7718 7.09875C12.5805 6.58279 13.083 6.0803 13.599 6.27158C14.5032 6.6068 15.513 6.18854 15.9153 5.31213Z" fill="#FED571"/>
</svg>
`;
const ACTIVE_BUNDLE_ICON = `<svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H24.5C28.9183 0 32.5 3.58172 32.5 8V12C32.5 16.4183 28.9183 20 24.5 20H8.5C4.08172 20 0.5 16.4183 0.5 12V8Z" fill="#3C67FF" fill-opacity="0.2"/>
<path d="M18.7245 8.62446C18.9588 8.39014 18.9588 8.01025 18.7245 7.77593C18.4901 7.54162 18.1102 7.54162 17.8759 7.77593L14.2759 11.3759C14.0416 11.6102 14.0416 11.9901 14.2759 12.2245C14.5102 12.4588 14.8901 12.4588 15.1245 12.2245L18.7245 8.62446Z" fill="#8AA4FF"/>
<path d="M15.7002 8.4002C15.7002 8.84202 15.342 9.2002 14.9002 9.2002C14.4584 9.2002 14.1002 8.84202 14.1002 8.4002C14.1002 7.95837 14.4584 7.6002 14.9002 7.6002C15.342 7.6002 15.7002 7.95837 15.7002 8.4002Z" fill="#8AA4FF"/>
<path d="M18.1002 12.4002C18.542 12.4002 18.9002 12.042 18.9002 11.6002C18.9002 11.1584 18.542 10.8002 18.1002 10.8002C17.6584 10.8002 17.3002 11.1584 17.3002 11.6002C17.3002 12.042 17.6584 12.4002 18.1002 12.4002Z" fill="#8AA4FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.1757 4.81172C17.518 3.37917 15.4824 3.37917 14.8247 4.81172C14.6843 5.11767 14.3318 5.26368 14.0161 5.14666C12.5381 4.59872 11.0987 6.03812 11.6467 7.51613C11.7637 7.83178 11.6177 8.18428 11.3117 8.32474C9.87917 8.98239 9.87917 11.018 11.3117 11.6757C11.6177 11.8161 11.7637 12.1686 11.6467 12.4843C11.0987 13.9623 12.5381 15.4017 14.0161 14.8537C14.3318 14.7367 14.6843 14.8827 14.8247 15.1887C15.4824 16.6212 17.518 16.6212 18.1757 15.1887C18.3161 14.8827 18.6686 14.7367 18.9843 14.8537C20.4623 15.4017 21.9017 13.9623 21.3537 12.4843C21.2367 12.1686 21.3827 11.8161 21.6887 11.6757C23.1212 11.018 23.1212 8.98239 21.6887 8.32474C21.3827 8.18428 21.2367 7.83178 21.3537 7.51613C21.9017 6.03812 20.4623 4.59872 18.9843 5.14666C18.6686 5.26368 18.3161 5.11767 18.1757 4.81172ZM15.9153 5.31238C16.1449 4.81228 16.8555 4.81228 17.0851 5.31238C17.4874 6.18878 18.4972 6.60704 19.4014 6.27183C19.9174 6.08054 20.4199 6.58303 20.2286 7.09899C19.8934 8.0032 20.3116 9.01297 21.188 9.41531C21.6881 9.64489 21.6881 10.3555 21.188 10.5851C20.3116 10.9874 19.8934 11.9972 20.2286 12.9014C20.4199 13.4174 19.9174 13.9199 19.4014 13.7286C18.4972 13.3934 17.4874 13.8116 17.0851 14.688C16.8555 15.1881 16.1449 15.1881 15.9153 14.688C15.513 13.8116 14.5032 13.3934 13.599 13.7286C13.083 13.9199 12.5805 13.4174 12.7718 12.9014C13.107 11.9972 12.6888 10.9874 11.8124 10.5851C11.3123 10.3555 11.3123 9.64489 11.8124 9.41531C12.6888 9.01297 13.107 8.0032 12.7718 7.09899C12.5805 6.58303 13.083 6.08054 13.599 6.27183C14.5032 6.60704 15.513 6.18878 15.9153 5.31238Z" fill="#8AA4FF"/>
</svg>
`;
const NO_BUNDLE_ICON = `<svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H24.5C28.9183 0 32.5 3.58172 32.5 8V12C32.5 16.4183 28.9183 20 24.5 20H8.5C4.08172 20 0.5 16.4183 0.5 12V8Z" fill="#333333"/>
<path d="M18.7245 8.62446C18.9588 8.39014 18.9588 8.01025 18.7245 7.77593C18.4901 7.54162 18.1102 7.54162 17.8759 7.77593L14.2759 11.3759C14.0416 11.6102 14.0416 11.9901 14.2759 12.2245C14.5102 12.4588 14.8901 12.4588 15.1245 12.2245L18.7245 8.62446Z" fill="#AAAAAA"/>
<path d="M15.7002 8.4002C15.7002 8.84202 15.342 9.2002 14.9002 9.2002C14.4584 9.2002 14.1002 8.84202 14.1002 8.4002C14.1002 7.95837 14.4584 7.6002 14.9002 7.6002C15.342 7.6002 15.7002 7.95837 15.7002 8.4002Z" fill="#AAAAAA"/>
<path d="M18.1002 12.4002C18.542 12.4002 18.9002 12.042 18.9002 11.6002C18.9002 11.1584 18.542 10.8002 18.1002 10.8002C17.6584 10.8002 17.3002 11.1584 17.3002 11.6002C17.3002 12.042 17.6584 12.4002 18.1002 12.4002Z" fill="#AAAAAA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.1757 4.81172C17.518 3.37917 15.4824 3.37917 14.8247 4.81172C14.6843 5.11767 14.3318 5.26368 14.0161 5.14666C12.5381 4.59872 11.0987 6.03812 11.6467 7.51613C11.7637 7.83178 11.6177 8.18428 11.3117 8.32474C9.87917 8.98239 9.87917 11.018 11.3117 11.6757C11.6177 11.8161 11.7637 12.1686 11.6467 12.4843C11.0987 13.9623 12.5381 15.4017 14.0161 14.8537C14.3318 14.7367 14.6843 14.8827 14.8247 15.1887C15.4824 16.6212 17.518 16.6212 18.1757 15.1887C18.3161 14.8827 18.6686 14.7367 18.9843 14.8537C20.4623 15.4017 21.9017 13.9623 21.3537 12.4843C21.2367 12.1686 21.3827 11.8161 21.6887 11.6757C23.1212 11.018 23.1212 8.98239 21.6887 8.32474C21.3827 8.18428 21.2367 7.83178 21.3537 7.51613C21.9017 6.03812 20.4623 4.59872 18.9843 5.14666C18.6686 5.26368 18.3161 5.11767 18.1757 4.81172ZM15.9153 5.31238C16.1449 4.81228 16.8555 4.81228 17.0851 5.31238C17.4874 6.18878 18.4972 6.60704 19.4014 6.27183C19.9174 6.08054 20.4199 6.58303 20.2286 7.09899C19.8934 8.0032 20.3116 9.01297 21.188 9.41531C21.6881 9.64489 21.6881 10.3555 21.188 10.5851C20.3116 10.9874 19.8934 11.9972 20.2286 12.9014C20.4199 13.4174 19.9174 13.9199 19.4014 13.7286C18.4972 13.3934 17.4874 13.8116 17.0851 14.688C16.8555 15.1881 16.1449 15.1881 15.9153 14.688C15.513 13.8116 14.5032 13.3934 13.599 13.7286C13.083 13.9199 12.5805 13.4174 12.7718 12.9014C13.107 11.9972 12.6888 10.9874 11.8124 10.5851C11.3123 10.3555 11.3123 9.64489 11.8124 9.41531C12.6888 9.01297 13.107 8.0032 12.7718 7.09899C12.5805 6.58303 13.083 6.08054 13.599 6.27183C14.5032 6.60704 15.513 6.18878 15.9153 5.31238Z" fill="#AAAAAA"/>
</svg>
`;

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'asyncDiscount'): void;
}>();

const bundleItems = computed(
  () =>
    props.values?.map((value) => ({
      id: ID,
      title: value,
    })) || [],
);
const hasActiveDeals = computed(() => !!props.deals?.length);

const actionLabel = computed(() => {
  if (!props.isProductPublished && bundleItems.value.length > 0) return 'Activate';
  return hasActiveDeals.value ? 'Edit' : 'Create';
});

const tooltipContent = computed(() => {
  if (!props.isProductPublished && bundleItems.value.length > 0) return 'Activate then Sync';
  return 'Create then Sync';
});

const bundleTitle = computed(() => {
  if (!props.isProductPublished && bundleItems.value.length > 0) return 'Bundle inactive';
  return hasActiveDeals.value ? `${props.deals?.length} bundle discount` : 'No bundle';
});

const statusIcon = computed(() => {
  if (!props.isProductPublished && bundleItems.value.length > 0) return INACTIVE_BUNDLE_ICON;
  return hasActiveDeals.value ? ACTIVE_BUNDLE_ICON : NO_BUNDLE_ICON;
});

const navigateToDiscountPage = () => {
  const path = props.discountId
    ? `/preferences/bundle/discount/${props.discountId}`
    : `/preferences/bundle-discount/new?objectBaseID=${props.objectBaseId}`;

  window.open(`${props.embedAppUrl}${path}`, '_blank');
};

const navigateToActivationPage = () => {
  if (!props.discountId) return;
  window.open(`${props.embedAppUrl}/preferences/bundle/discount/${props.discountId}`, '_blank');
};

const handlePrimaryAction = () => {
  if (!props.isProductPublished && bundleItems.value.length > 0) {
    navigateToActivationPage();
  } else {
    navigateToDiscountPage();
  }
};

const triggerDiscountSync = () => emit('asyncDiscount');

const iframeWindow = ref<Window | null>(null);

onMounted(() => {
  const iframe = document.querySelector<HTMLIFrameElement>('.iframe');
  if (iframe?.contentWindow) {
    iframeWindow.value = iframe.contentWindow;
    iframeWindow.value.addEventListener('editor:redirect-bundle-discount-page', navigateToDiscountPage);
  }
});

onUnmounted(() => {
  iframeWindow.value?.removeEventListener('editor:redirect-bundle-discount-page', navigateToDiscountPage);
});
</script>

<template>
  <div class="gemx-control mt-8">
    <div v-if="isProductDiscountLoading" class="mb-16 flex flex-col gap-8">
      <div class="bg-dark-400 flex h-[56px] animate-pulse rounded-xl"></div>
    </div>
    <GTooltip
      v-else
      :disabled="isProductPublished && bundleItems.length > 0"
      class="w-full"
      placement="top"
      :is-teleport="true"
      :margin-top="4">
      <div
        class="bg-dark-400 hover:bg-dark-300 group flex h-[56px] w-full items-center justify-between rounded-xl px-8">
        <div class="flex items-center gap-4">
          <div class="flex h-40 w-40 items-center justify-center" v-html="statusIcon"></div>
          <span class="text-text-dark-500 text-12">{{ bundleTitle }}</span>
        </div>
        <div class="hidden cursor-pointer items-center gap-8 pr-8 group-hover:flex">
          <svg
            v-if="!isLoading"
            @click="triggerDiscountSync"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.80039 11.4C6.80039 11.7314 7.06902 12 7.40039 12C7.73176 12 8.00039 11.7314 8.00039 11.4C8.00039 10.0745 9.07491 9 10.4004 9H15.6532L14.7544 9.99862C14.5327 10.2449 14.5527 10.6243 14.799 10.846C15.0453 11.0677 15.4247 11.0477 15.6464 10.8014L17.4464 8.80138C17.6598 8.56418 17.6503 8.20138 17.4247 7.97574L15.6247 6.17574C15.3903 5.94142 15.0104 5.94142 14.7761 6.17574C14.5418 6.41005 14.5418 6.78995 14.7761 7.02426L15.5519 7.8H10.4004C8.41217 7.8 6.80039 9.41177 6.80039 11.4Z"
              fill="#F9F9F9" />
            <path
              d="M17.2004 12.6C17.2004 12.2686 16.9318 12 16.6004 12C16.269 12 16.0004 12.2686 16.0004 12.6C16.0004 13.9255 14.9259 15 13.6004 15H8.34761L9.24637 14.0014C9.46804 13.7551 9.44808 13.3757 9.20177 13.154C8.95546 12.9323 8.57609 12.9523 8.35442 13.1986L6.55442 15.1986C6.34094 15.4358 6.35048 15.7986 6.57613 16.0243L8.37613 17.8243C8.61044 18.0586 8.99034 18.0586 9.22466 17.8243C9.45897 17.5899 9.45897 17.21 9.22466 16.9757L8.44892 16.2H13.6004C15.5886 16.2 17.2004 14.5882 17.2004 12.6Z"
              fill="#F9F9F9" />
          </svg>
          <g-base-icon
            v-else
            class="animate-spin text-white ease-in-out"
            name="polaris-spinner"
            width="20"
            height="20" />
          <span
            @click="handlePrimaryAction"
            class="text-primary-200 text-12 hidden font-medium no-underline group-hover:block"
            :class="{ 'font-semibold': !isProductPublished && bundleItems.length > 0 }">
            {{ actionLabel }}
          </span>
        </div>
      </div>
      <template #content>
        {{ tooltipContent }}
      </template>
    </GTooltip>
  </div>
</template>
