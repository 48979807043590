import { ref } from 'vue';
import type { Product } from '../../types';
import type { MultiProductPickerEmit, MultiProductPickerProps } from '../type';

export const useHandleArrangeProducts = (emit: MultiProductPickerEmit, props: MultiProductPickerProps) => {
  const contentAlertRange = ref<string>('');

  const closeAlertArrange = () => {
    contentAlertRange.value = '';
  };

  const handleSubmitReorder = (listSelected: Product[], _from?: number, _to?: number) => {
    if (!listSelected.length) return;
    emit('setValueSelected', listSelected);
  };

  const handleUnselected = (productId?: string) => {
    if (!productId) return;
    const newList = (props.selectedProductList ?? []).filter((item) => item.id !== productId);
    if (newList.length === 0) {
      contentAlertRange.value = 'You must select at least 1 product to display';
      return;
    }
    emit('setValueSelected', newList ?? []);
  };

  return { handleSubmitReorder, handleUnselected, closeAlertArrange, contentAlertRange };
};
