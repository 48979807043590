<script lang="ts" setup>
import { cn } from '@gem/common';
import { GButton } from '@gem/uikit-v2';
import type { ProductSelectedProps } from '../type';
import { computed } from 'vue';
import ProductSourceSkeleton from './ProductSourceSkeleton.vue';

const props = defineProps<ProductSelectedProps>();

const emit = defineEmits<{
  (e: 'onSyncProduct'): void;
}>();

const handleSync = () => {
  emit('onSyncProduct');
};

const productDisplay = computed(() => {
  if (props.isNoProductInStore) {
    return {
      title: 'No product founded',
      image: undefined,
    };
  }
  if (props.isNoAssignedProduct && props.productStatusByPageType === 'dynamic' && !props.isPageDefault) {
    return {
      title: 'No product assigned',
      image: undefined,
    };
  }
  return props.convertProductDisplay;
});
</script>
<template>
  <ProductSourceSkeleton v-if="isLoadingProductSrc" />
  <div
    v-else
    class="bg-dark-400 hover:bg-dark-300 group relative flex h-[56px] cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl border border-transparent p-8"
    :class="{
      '!bg-dark-250': isSync,
      'border-primary-300': productListVisibility,
    }">
    <img
      v-if="productDisplay?.image"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      :src="productDisplay?.image"
      alt="product feature img" />
    <div v-else class="rounded-medium aspect-square h-40 w-40 shrink-0 overflow-hidden">
      <img src="../../images/collection-thumbnail.png" alt="no image transparent" />
    </div>
    <p
      class="text-light-450 font-regular text-12 line-clamp-2 self-center truncate whitespace-pre-wrap break-words leading-[14px]">
      {{ productDisplay?.title }}
    </p>
    <div
      class="z-5 absolute right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
      :class="cn({ '!flex': isSync })"
      :style="{
        background: 'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
      }">
      <g-tooltip placement="top" :is-teleport="true" content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4">
        <GButton :loading="isSync" type="ghost" size="small" only-icon="polaris-refresh" @click.stop="handleSync" />
        <template #content>Sync product</template>
      </g-tooltip>
      <g-tooltip placement="top" :is-teleport="true" content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4">
        <GButton type="ghost" size="small" only-icon="polaris-edit" />
        <template #content>Edit</template>
      </g-tooltip>
    </div>
  </div>
</template>
