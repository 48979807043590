<script lang="ts" setup>
import ProductSourceSkeleton from '../../product/components/ProductSourceSkeleton.vue';
import { PRODUCT_SOURCE_TYPE } from '../constant';
import { useGetDataProductSelected } from '../hooks/useGetDataProductSelected';
import type { ProductSelectedProp } from '../type';
import CollectionSelected from './CollectionSelected.vue';
import IconSource from './IconSource.vue';
import ProductSelected from './ProductSelected.vue';
import RelatedSelected from './RelatedSelected.vue';

const props = defineProps<ProductSelectedProp>();
const emit = defineEmits<{
  (e: 'refresh'): void;
}>();

const {
  titleProduct,
  productsLengthCollection,
  collectionTitle,
  titleRelated,
  lineClampClass,
  isShowProductExcludeLength,
  productsExcludeLength,
  classContainerProductSelected,
  isNoAssignedCollections,
} = useGetDataProductSelected(props);

const refresh = () => {
  emit('refresh');
};

// isLoadingProductSrc: To show loading of product source
</script>
<template>
  <ProductSourceSkeleton v-if="isLoadingProductSrc" />
  <div v-else>
    <div v-if="checkListEmpty?.isNoProductInStore" :class="classContainerProductSelected">
      <img
        class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
        src="../../images/collection-thumbnail.png"
        alt="product feature img" />
      <p class="text-light-200 font-regular text-12 line-clamp-1 leading-[14px]">No product founded</p>
      <IconSource :is-loading="isLoading" @refresh="emit('refresh')" />
    </div>
    <div v-else>
      <CollectionSelected
        v-if="pageType === 'GP_COLLECTION' || productSrcType === PRODUCT_SOURCE_TYPE.COLLECTION"
        :class-container="classContainerProductSelected"
        :is-loading="isLoading"
        :item-display="collectionDisplay"
        :title="collectionTitle"
        :sub-title="productsLengthCollection"
        :is-no-assigned-collections="isNoAssignedCollections"
        :refresh="refresh" />
      <ProductSelected
        v-else-if="productSrcType === PRODUCT_SOURCE_TYPE.PICK_PRODUCT"
        :class-container="classContainerProductSelected"
        :is-loading="isLoading"
        :list-product="list"
        :title="titleProduct"
        :refresh="refresh" />
      <RelatedSelected
        v-else
        :class-container="classContainerProductSelected"
        :title="titleRelated"
        :sub-title="productsExcludeLength"
        :line-clamp-class="lineClampClass"
        :is-loading="isLoading"
        :refresh="refresh"
        :is-show-sub-title="isShowProductExcludeLength" />
    </div>
  </div>
</template>
