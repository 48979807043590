<script setup lang="ts">
import { computed } from 'vue';
import type { TypographyProps } from './types';

import InputUnit from '../InputUnit.vue';
import Select from '../Select.vue';

const fontStyle = [
  { label: 'Normal', value: 'normal' },
  { label: 'Italic', value: 'italic' },
];

type Props = {
  resetKey?: string | number;
  value?: TypographyProps;
  fontFamily?: {
    label: string;
    value: string;
    metaData?: string;
  }[];
  fontWeight?: {
    label: string;
    value: string;
  }[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'change', name: string, value: any): void;
  (e: 'on-change', name: string, value: any): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
}>();
const change = (name: string, value: any) => {
  emit('change', name, value);
};
const onControlChange = (name: string, value: any) => {
  emit('on-change', name, value);
};
const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const fontWeightValue = computed(() => {
  if (props.fontWeight && props.fontWeight.length > 0) {
    const findFontWeight = props.fontWeight?.find((item) => item.value === props.value?.fontWeight);

    return findFontWeight?.value || props.fontWeight[0].value;
  }
  return undefined;
});
</script>
<template>
  <div class="flex flex-col gap-8">
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Font size</label>
      <div class="w-[120px]">
        <InputUnit
          id="fontSize"
          :key="resetKey"
          :units="['px']"
          :value="value?.fontSize"
          :control-change="change"
          @control-on-change="onControlChange" />
      </div>
    </div>

    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Line height</label>
      <div class="w-[120px]">
        <InputUnit
          id="lineHeight"
          :key="resetKey"
          :value="value?.lineHeight"
          :units="['px', '%']"
          :control-change="change"
          @control-on-change="onControlChange" />
      </div>
    </div>
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Font style</label>
      <div class="w-[120px]">
        <Select id="fontStyle" :key="resetKey" :options="fontStyle" :value="value?.fontStyle" @control-change="change">
        </Select>
      </div>
    </div>
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Font family</label>
      <div class="w-[120px]">
        <Select
          id="fontFamily"
          :key="resetKey"
          disable-default
          :options="fontFamily"
          :value="value?.fontFamily"
          @control-change="change">
          <template #content="{ props: propData }">
            <div
              class="hover:bg-light-100/20 text-light-100 text-12 group relative flex cursor-default select-none items-center truncate whitespace-nowrap rounded-xl py-8 px-24 leading-[14px]"
              :class="{ 'bg-text-light-300 !text-light-450': propData.isActive && !propData.isHoverSelect }"
              :data-value="propData.item.id"
              :title="`${propData.item.name} (${propData.item.value.metaData})`"
              @click="propData.onSelect(propData.item)">
              <span v-if="propData.isActive" class="text-light-450 absolute inset-y-0 left-4 flex items-center">
                <GBaseIcon name="status-check" width="16" height="16" />
              </span>
              <span class="font-regular truncate"> {{ propData.item.name }} ({{ propData.item.value.metaData }}) </span>
              <span
                class="text-light-450 absolute inset-y-0 right-4 hidden cursor-pointer items-center group-hover:flex"
                @click.stop="onClickSubAction('editGlobalStyle', 'gs-font-manager')">
                <GBaseIcon name="edit" width="16" height="16" />
              </span>
            </div>
          </template>
        </Select>
      </div>
    </div>
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Font weight</label>
      <div class="w-[120px]">
        <Select
          id="fontWeight"
          :key="resetKey"
          disable-default
          :options="fontWeight"
          :value="fontWeightValue"
          @control-change="change" />
      </div>
    </div>
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Letter spacing</label>
      <div class="w-[120px]">
        <InputUnit
          id="letterSpacing"
          :key="resetKey"
          :units="['px']"
          :value="value?.letterSpacing"
          :control-change="change"
          @control-on-change="onControlChange" />
      </div>
    </div>
  </div>
</template>
