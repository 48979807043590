import { computed } from 'vue';
import type { ProductSelectedProp } from '../type';
import { PRODUCT_SOURCE_TYPE } from '../constant';

export const useGetDataProductSelected = (props: ProductSelectedProp) => {
  const productSrcType = computed(() => props.productSrcType);
  const pageType = computed(() => props.pageType);
  const isGPCollection = computed(() => pageType.value === 'GP_COLLECTION');
  const isNoAssignedCollections = computed(() => props.checkListEmpty?.isNoAssignedCollections && isGPCollection.value);

  const noProductFounded = computed(() => {
    const productNotDraftArchived = (props.list ?? []).find(
      (p) => p.status?.toLocaleUpperCase() !== 'DRAFT' && p.status?.toLocaleUpperCase() !== 'ARCHIVED',
    );
    return !productNotDraftArchived;
  });

  // Pick products
  const titleProduct = computed(() => {
    if (props.list.length === 1) return props.list?.[0].title ?? '';
    if (props.list.length) return `${props.list.length} products`;
    return 'No product selected';
  });

  // Pick collection
  const productsLengthCollection = computed(() => {
    const length = props.collectionDisplay?.productCount ?? 0;
    return (length ?? 0) < 2 ? `${length ?? 0} product` : `${length} products`;
  });
  const collectionTitle = computed(() => {
    if (isNoAssignedCollections.value) {
      return 'No collection assigned';
    }

    return props.collectionDisplay?.id ? props.collectionDisplay?.title : 'No collection selected';
  });

  // Related products
  const relatedTag = computed(() => props.valueSetting?.relatedTag);
  const relatedCollectionExclude = computed(() => props.valueSetting?.relatedCollectionExclude);
  const relatedTagsExclude = computed(() => props.valueSetting?.relatedTagsExclude);
  const isRelatedTags = computed(() => relatedTag.value?.includes('tags'));
  const isRelatedCollection = computed(() => relatedTag.value?.includes('collection'));
  const titleRelated = computed(() => {
    const relatedTag = props.valueSetting?.relatedTag;
    if (relatedTag?.includes('collection')) return 'Same collection with assigned product';
    if (isRelatedTags.value) return 'Same tag with assigned product';
    return 'Same vendor with assigned product';
  });
  const isShowProductExcludeLength = computed(() => isRelatedCollection.value || isRelatedTags.value);

  const classContainerProductSelected = computed(() => ({
    'hover:bg-dark-300 bg-dark-400 group relative flex h-[56px] cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl p-8 border-transparent border':
      true,
    '!bg-dark-250': props.isLoading,
    '!border-primary-300': props.productListVisibility,
  }));
  const productsExcludeLength = computed(() => {
    if (productSrcType.value !== PRODUCT_SOURCE_TYPE.RELATED_PRODUCT) return '';
    if (isRelatedCollection.value) {
      const excludeLengths = (relatedCollectionExclude.value ? relatedCollectionExclude.value?.split(',') ?? [] : [])
        .length;
      return excludeLengths > 1 ? `Exclude ${excludeLengths} collections` : `Exclude ${excludeLengths} collection`;
    }
    if (isRelatedTags.value) {
      const excludeLengths = (relatedTagsExclude.value ? relatedTagsExclude.value?.split(',') ?? [] : []).length;
      return excludeLengths > 1 ? `Exclude ${excludeLengths} tags` : `Exclude ${excludeLengths} tag`;
    }
    return '';
  });

  const lineClampClass = computed(() => (relatedTag.value?.includes('vendor') ? 'line-clamp-2' : 'line-clamp-1'));
  return {
    titleProduct,
    productsLengthCollection,
    collectionTitle,
    titleRelated,
    lineClampClass,
    isShowProductExcludeLength,
    productsExcludeLength,
    classContainerProductSelected,
    noProductFounded,
    isNoAssignedCollections,
  };
};
