<script setup lang="ts">
import { OptionValue } from '@gem/element-setting-ui';
import { GPopover } from '@gem/uikit-v2';
import CollectionPicker from './components/CollectionPicker.vue';
import MultiProductPicker from './components/MultiProductPicker.vue';
import RelatedPicker from './components/RelatedPicker.vue';
import SearchComponent from './components/SearchComponent.vue';
import { useHandleDataMultiProductModal } from './hooks/useHandleDataMultiProductModal';
import { useProductVariantSelected } from '../hooks/useProductVariantSelected';
import type { ProductListPickerProps, ProductListPickerTypesEmit } from './type';
import VariantPicker from './components/VariantPicker.vue';
import { PRODUCT_SOURCE_TYPE } from '../../product-list/constant';
import type { Product } from '../types';
import { useGetStatusModal } from './hooks/useGetStatusModal';
import HeaderPicker from './components/HeaderPicker.vue';
import FooterPicker from './components/FooterPicker.vue';

const props = defineProps<ProductListPickerProps>();
const emit = defineEmits<ProductListPickerTypesEmit>();

const { productVariantSelect, handleChangeVariantOption, handleDeleteVariant } = useProductVariantSelected({
  value: props.variantSelected,
});

const {
  close: handleClose,
  openModal,
  searchKeyword,
  searchProductListReference,
  multiProductPickerReference,
  onClickBtn,
  handleSelectTypePicker,
  setValueSelectedProducts,
  setValueSelectedCollection,
  optionList,
  handleOpenAssign,
  stepPicker,
  backToArrangeProduct,
  titleBtn,
  pickerTitle,
  offerTitle,
  productSelected,
  handleSelectProductCurrent,
} = useHandleDataMultiProductModal(emit, props);

const {
  isShowSearch,
  isShowBtnInMultiProduct,
  isShowBtnManageAssign,
  isShowOptions,
  isShowCollections,
  isDisabledBtnMultiProduct,
} = useGetStatusModal(props, stepPicker, optionList, multiProductPickerReference);

const onChangeVariant = (value: string) => {
  if (value) handleChangeVariantOption(productSelected.value as Product, value);
  else handleDeleteVariant(productSelected.value?.id ?? '');
  emit('setPreVariantSelected', productVariantSelect);
  backToArrangeProduct();
};

const onBlurSearch = () => {
  if (!searchProductListReference?.value) return;
  searchProductListReference.value?.closeSearchSetting();
};
</script>

<template>
  <div>
    <div ref="reference">
      <GPopover
        ref="popoverReference"
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        placement="right-start"
        :offset-top="-8"
        :no-padding="true"
        :placement-offset="20"
        :enable-flip="false"
        :overlay-container="'#sidebar'"
        :ignore-outside-class="['gp-combo-layout', 'popover-control-modal', 'gallery-model-container']"
        :detect-side-bar="true"
        :force-open="open"
        @open="openModal"
        @close="handleClose"
        @click-out-side="handleClose">
        <slot name="btn-popover"></slot>
        <template #content="{ close }">
          <div class="max-h-[516px] w-[280px]">
            <HeaderPicker
              :productSelected="productSelected"
              :stepPicker="stepPicker"
              :productSrcType="productSrcType"
              :backToArrangeProduct="backToArrangeProduct"
              :onBlurSearch="onBlurSearch"
              :isLoading="isSync"
              :refresh="() => emit('refresh')"
              :offerTitle="offerTitle"
              :pickerTitle="pickerTitle"
              :close="close" />
            <div v-if="!productSelected">
              <div class="flex flex-col gap-8 py-16">
                <div v-if="isShowOptions" class="px-16">
                  <OptionValue
                    id="productSrc"
                    :value="productSrcType"
                    :options="optionList"
                    @control-change="handleSelectTypePicker" />
                </div>
                <SearchComponent
                  v-if="isShowSearch"
                  ref="searchProductListReference"
                  :product-src-type="productSrcType"
                  :step-picker="stepPicker"
                  @on-search="(value: string) => emit('onSearch', value)" />
                <MultiProductPicker
                  v-if="productSrcType === PRODUCT_SOURCE_TYPE.PICK_PRODUCT"
                  ref="multiProductPickerReference"
                  :selectedProductList="selectedProductList"
                  :list="list"
                  :page-type="pageType"
                  :max-allow-selected="maxAllowSelected"
                  :variant-selected="productVariantSelect"
                  :search-keyword="searchKeyword"
                  :is-post-purchase="isPostPurchase"
                  :is-load-more="isFetchingNextPage"
                  :is-not-have-product="checkListEmpty?.isNoProductInStore"
                  :navigate-to-product-shopify="navigateToProductShopify"
                  :step-picker="stepPicker"
                  :isLoading="isLoading"
                  :isLoadingArrange="isLoadingArrange"
                  @close="close"
                  @set-value-selected="setValueSelectedProducts"
                  @show-more="emit('showMore', 'fetch')"
                  @select-variant="handleSelectProductCurrent" />
                <CollectionPicker
                  v-if="isShowCollections"
                  :value="valueCollection"
                  :list="collectionList"
                  :is-load-more="isFetchingNextPage"
                  :search-keyword="searchKeyword"
                  :navigate-to-collection-shopify="navigateToCollectionShopify"
                  :is-not-have-collection="checkListEmpty?.isNoCollectionInStore"
                  :is-not-have-assigned="checkListEmpty?.isNoAssignedCollections"
                  :page-type="pageType"
                  :isLoading="isLoading"
                  @set-value-selected-collection="setValueSelectedCollection"
                  @show-more="emit('showMore', 'fetch')"
                  @open-assign-modal="handleOpenAssign" />
                <RelatedPicker
                  v-if="productSrcType === PRODUCT_SOURCE_TYPE.RELATED_PRODUCT"
                  :value="value"
                  @control-change="(value: any) => emit('controlChangeRelated', value)" />
              </div>
              <FooterPicker
                :isShowBtnInMultiProduct="isShowBtnInMultiProduct"
                :isDisabledBtnMultiProduct="isDisabledBtnMultiProduct"
                :isShowBtnManageAssign="isShowBtnManageAssign"
                :onClickBtn="onClickBtn"
                :handleOpenAssign="handleOpenAssign"
                :titleBtn="titleBtn" />
            </div>
            <VariantPicker
              v-else
              :product-selected="productSelected"
              :product-variant-select="productVariantSelect"
              @save="onChangeVariant" />
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
