import { cloneDeepObject } from '@gem/common';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { Product } from '../../types';
import type { MultiProductPickerProps, ProductListPickerTypesEmit, ProductSource } from '../type';

export const useSetValueSelectedProducts = (
  props: MultiProductPickerProps,
  _closeAlertPick: () => void,
  contentAlert: Ref<string>,
) => {
  const productList = ref<Product[]>(cloneDeepObject(props.selectedProductList ?? []));
  const PRODUCT_PICK_LIMIT = 20;
  const productPickLimit = computed(() => (props?.maxAllowSelected ? props.maxAllowSelected : PRODUCT_PICK_LIMIT));

  const checkWarning = (productLength: number) => {
    if (productLength > productPickLimit.value) {
      contentAlert.value = `You can’t select more than ${productPickLimit.value} products`;
    }
    if (productLength < 1) {
      contentAlert.value = 'You must select at least 1 product to display';
    }
    if (1 <= productLength && productLength <= productPickLimit.value) {
      contentAlert.value = '';
    }
  };
  const handleSetValueHaveProduct = (item: Product) => {
    const index = productList.value.findIndex((i) => i.id === item.id);
    productList.value.splice(index, 1);
    checkWarning(productList.value.length);
  };

  const handleSetValueNotHaveProduct = (item: Product) => {
    productList.value?.push(item);
    checkWarning(productList.value.length);
  };

  const setValueSelected = (item?: Product) => {
    if (!item?.id) return;
    const product = productList.value?.find((i) => i.id === item.id);
    if (!product) {
      handleSetValueNotHaveProduct(item);
      return;
    }
    handleSetValueHaveProduct(item);
  };

  watch(
    () => props.selectedProductList,
    (newValue) => {
      productList.value = cloneDeepObject(newValue ?? []);
    },
  );

  const handleUnselectedAll = () => {
    if (productList.value.length === 0) return;
    productList.value = [];
    checkWarning(productList.value.length);
  };

  return { productList, setValueSelected, handleUnselectedAll };
};

export const useSetValueModal = (emit: ProductListPickerTypesEmit, searchProductListReference: Ref<any>) => {
  const handleSelectTypePicker = (value: ProductSource) => {
    emit('select-picker-type', value);
    if (!searchProductListReference.value) return;
    searchProductListReference.value.closeSearchSetting();
  };

  const setValueSelectedProducts = (value?: Product[]) => {
    emit('setValueSelected', value);
  };

  const setValueSelectedCollection = (value?: string) => {
    if (value) {
      emit('setValueSelectedCollection', value);
    }
  };

  return { handleSelectTypePicker, setValueSelectedProducts, setValueSelectedCollection };
};
