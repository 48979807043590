<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ itemsLength?: number; height?: string }>();
const itemsLength = computed(() => props.itemsLength ?? 5);
</script>
<template>
  <div
    class="px-16"
    :style="{
      height: height ?? 'auto',
    }">
    <div
      v-for="index in itemsLength"
      :key="index"
      class="flex h-[56px] w-full animate-pulse cursor-pointer items-center gap-8 overflow-hidden rounded-xl border border-transparent bg-transparent p-8">
      <div class="bg-dark-250 h-40 w-40 rounded-[6px]"></div>
      <div class="bg-dark-250 h-14 flex-1 rounded-[6px]"></div>
    </div>
  </div>
</template>
