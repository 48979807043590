import { useInfiniteScroll } from '@vueuse/core';
import { computed, ref, watchEffect } from 'vue';
import { areEqualArrays } from '../helper';
import type { MultiProductPickerEmit, MultiProductPickerProps } from '../type';
import { useSetValueSelectedProducts } from './useSetValueSelected';
import { cloneDeepObject } from '@gem/common';
import type { Product } from '../../types';
export const useHandleDataMultiProductPicker = (emit: MultiProductPickerEmit, props: MultiProductPickerProps) => {
  // Handle show more
  const productInfinityScroll = ref<HTMLElement | null>(null);
  const contentAlert = ref<string>('');

  const closeAlertPick = () => {
    contentAlert.value = '';
  };

  useInfiniteScroll(
    productInfinityScroll,
    () => {
      emit('showMore');
    },
    { distance: 100 },
  );

  const { productList, setValueSelected, handleUnselectedAll } = useSetValueSelectedProducts(
    props,
    closeAlertPick,
    contentAlert,
  );

  const save = () => {
    emit('setValueSelected', productList.value);
    isDisabledSave.value = true;
  };

  // Handle disable save
  const productIdsCloneDeep = ref<string[]>([]);
  const isDisabledSave = ref<boolean>(true);
  const isDisableUnselectAll = computed(() => (productList.value ?? []).length === 0 || props.isLoading);
  const PRODUCT_PICK_LIMIT = 20;
  const productPickLimit = computed(() => (props?.maxAllowSelected ? props.maxAllowSelected : PRODUCT_PICK_LIMIT));
  const isPostPurchasePage = computed(() => props.pageType === 'POST_PURCHASE');

  watchEffect(() => {
    const productIdList = (productList.value ?? []).map((item) => item.id);
    if (productIdList.length === 0 || (productPickLimit.value && productIdList.length > productPickLimit.value)) {
      isDisabledSave.value = true;
      return;
    }
    const originalProductIdList = (cloneDeepObject(props?.selectedProductList) ?? []).map((item) => item.id);
    productIdsCloneDeep.value = [...originalProductIdList];

    isDisabledSave.value = areEqualArrays(productIdsCloneDeep.value, productIdList);
  });

  const handleSelectProductVariant = (item: any) => {
    emit('selectVariant', item);
  };

  const checkShowVariantTitle = (item: Product) => {
    return props.pageType === 'POST_PURCHASE' && item.variants.totalCount > 1;
  };

  return {
    productInfinityScroll,
    isPostPurchasePage,
    setValueSelected,
    productList,
    save,
    isDisabledSave,
    handleSelectProductVariant,
    closeAlertPick,
    handleUnselectedAll,
    isDisableUnselectAll,
    contentAlertPick: contentAlert,
    checkShowVariantTitle,
  };
};
