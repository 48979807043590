import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';
import { PRODUCT_SOURCE_TYPE } from '../../../product-list/constant';
import type { OptionType, ProductListPickerProps } from '../type';
import { STEP_PICKER } from '../constant';

export const useGetStatusModal = (
  props: ProductListPickerProps,
  stepPicker: Ref<STEP_PICKER>,
  optionList: ComputedRef<OptionType[]>,
  multiProductPickerReference: Ref<any>,
) => {
  const isShowSearch = computed(() => {
    switch (props.productSrcType) {
      case PRODUCT_SOURCE_TYPE.COLLECTION:
        return !props.checkListEmpty?.isNoCollectionInStore;
      case PRODUCT_SOURCE_TYPE.PICK_PRODUCT:
        return !props.checkListEmpty?.isNoProductInStore && stepPicker.value === STEP_PICKER.PICK_PRODUCT;
      case PRODUCT_SOURCE_TYPE.RELATED_PRODUCT:
        return false;
      case PRODUCT_SOURCE_TYPE.DYNAMIC_COLLECTION:
        return !props.checkListEmpty?.isNoAssignedCollections || props.isPageDefault;
      default:
        return true;
    }
  });

  const isShowBtnInMultiProduct = computed(
    () => props.productSrcType === PRODUCT_SOURCE_TYPE.PICK_PRODUCT && !props.checkListEmpty?.isNoProductInStore,
  );

  const isShowBtnManageAssign = computed(
    () =>
      props.productSrcType === PRODUCT_SOURCE_TYPE.DYNAMIC_COLLECTION &&
      (!props.checkListEmpty?.isNoAssignedCollections || props.isPageDefault),
  );

  const isShowOptions = computed(() => {
    return (
      !!optionList.value.length &&
      props.pageType !== 'GP_COLLECTION' &&
      !(props.productSrcType === PRODUCT_SOURCE_TYPE.PICK_PRODUCT && stepPicker?.value === STEP_PICKER.PICK_PRODUCT)
    );
  });

  const isShowCollections = computed(
    () =>
      props.productSrcType === PRODUCT_SOURCE_TYPE.COLLECTION ||
      props.productSrcType === PRODUCT_SOURCE_TYPE.DYNAMIC_COLLECTION,
  );

  const isDisabledBtnMultiProduct = computed(
    () => stepPicker.value === STEP_PICKER.PICK_PRODUCT && multiProductPickerReference?.value?.isDisabledSave,
  );

  return {
    isShowSearch,
    isShowBtnInMultiProduct,
    isShowBtnManageAssign,
    isShowOptions,
    isShowCollections,
    isDisabledBtnMultiProduct,
  };
};
