<script lang="ts" setup>
import MultiProductModal from '../modal/product-list-picker-modal/MultiProductModal.vue';
import ProductSource from './components/ProductSource.vue';
import { useHandlePickMultiProduct } from './hooks/useHandlePickMultiProduct';
import type { PickMultiProductEmit, PickMultiProductProps } from './type';

const props = defineProps<PickMultiProductProps>();
const emit = defineEmits<PickMultiProductEmit>();

const {
  isSalePage,
  listSortable,
  closeModal,
  openModal,
  setValueSelected,
  setPreVariantSelected,
  onSearch,
  collectionSelected,
  handleSelectPickerType,
  setValueSelectedCollection,
  controlChangeRelated,
  productListVisibility,
  cloneList,
} = useHandlePickMultiProduct(props, emit);

defineExpose({
  productListVisibility: productListVisibility,
});
</script>
<template>
  <div class="gemx-control">
    <MultiProductModal
      :selectedProductList="cloneList"
      :value="value"
      :value-collection="valueCollection"
      :list="listSortable"
      :type="'Product'"
      :open="productListVisibility"
      :is-loading="isLoading"
      :isSync="isSync"
      :isLoadingArrange="isLoadingArrange"
      :is-fetching-next-page="isFetchingNextPage"
      :is-post-purchase="isPostPurchase"
      :max="max"
      :sync-percent="syncPercent"
      :page-type="pageType"
      :max-allow-selected="maxAllowSelected"
      :input-search-component="inputSearchComponent"
      :warning-message="warningMessage"
      :variant-selected="variantSelected"
      :product-src-type="productSrcType"
      :collection-list="collectionList"
      :navigate-to-collection-shopify="navigateToCollectionShopify"
      :navigate-to-product-shopify="navigateToProductShopify"
      :collection-display="collectionDisplay"
      :check-list-empty="checkListEmpty"
      :openAssignModal="openAssignModal"
      :isPageDefault="isPageDefault"
      @select-picker-type="handleSelectPickerType"
      @show-more="emit('showMore', 'fetch')"
      @refresh="emit('controlRefresh')"
      @close="closeModal"
      @open-modal="openModal"
      @set-value-selected="setValueSelected"
      @set-value-selected-collection="setValueSelectedCollection"
      @set-pre-variant-selected="setPreVariantSelected"
      @control-change-related="controlChangeRelated"
      @on-search="onSearch">
      <template #btn-popover>
        <ProductSource
          :value-setting="value"
          :list="cloneList ?? []"
          :collection-display="collectionDisplay"
          :collection-selected="collectionSelected"
          :product-list-visibility="productListVisibility"
          :product-src-type="productSrcType"
          :is-sale-page="isSalePage"
          :is-loading="isSync"
          :page-type="pageType"
          :isLoadingProductSrc="isLoadingProductSrc"
          :check-list-empty="checkListEmpty"
          :isPageDefault="isPageDefault"
          @refresh="emit('controlRefresh')" />
      </template>
    </MultiProductModal>
  </div>
</template>
