import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import type { OptionType, ProductListPickerProps, ProductListPickerTypesEmit } from '../type';
import { useSetValueModal } from './useSetValueSelected';
import { PRODUCT_SOURCE_TYPE } from '../../../product-list/constant';
import { STEP_PICKER } from '../constant';
import type { PreVariantSelected, Product } from '../../types';
export const useHandleDataMultiProductModal = (emit: ProductListPickerTypesEmit, props: ProductListPickerProps) => {
  const multiProductPickerReference = ref();
  const searchProductListReference = ref();
  const stepPicker = ref<STEP_PICKER>(STEP_PICKER.ARRANGE);
  const isPickProduct = computed(() => stepPicker.value === STEP_PICKER.PICK_PRODUCT);
  const searchKeyword = computed(() => searchProductListReference.value?.searchKeyword || '');
  const titleBtn = computed(() => (isPickProduct.value ? 'Save' : 'Pick products'));
  const onClickBtn = computed(() => (isPickProduct.value ? handleSave : nextToPickProduct));
  const pickerTitle = computed(() => (isPickProduct.value ? 'Pick products' : 'Select product'));
  const productSelected = ref<null | Product>(null);

  const optionList: ComputedRef<OptionType[]> = computed(() => {
    if (props.pageType === 'POST_PURCHASE') return [];
    const list = [
      {
        id: 'product',
        label: 'Products',
        value: PRODUCT_SOURCE_TYPE.PICK_PRODUCT,
      },
      {
        id: 'collection',
        label: 'Collection',
        value: PRODUCT_SOURCE_TYPE.COLLECTION,
      },
    ];
    if (props.pageType === 'GP_PRODUCT') {
      list.push({
        id: 'related',
        label: 'Related',
        value: PRODUCT_SOURCE_TYPE.RELATED_PRODUCT,
      });
    }
    return list;
  });

  const { handleSelectTypePicker, setValueSelectedProducts, setValueSelectedCollection } = useSetValueModal(
    emit,
    searchProductListReference,
  );

  const handleSelectProductCurrent = (item: Product) => {
    productSelected.value = item;
    stepPicker.value = STEP_PICKER.PICK_VARIANT;
  };

  const handleSaveVariantSelected = (productVariantSelect: PreVariantSelected) => {
    emit('setPreVariantSelected', productVariantSelect);
    productSelected.value = null;
  };

  const offerTitle = computed(() => {
    if (productSelected.value) return productSelected.value.title;
    return 'Select product';
  });

  const nextToPickProduct = () => {
    stepPicker.value = STEP_PICKER.PICK_PRODUCT;
  };
  const backToArrangeProduct = () => {
    productSelected.value = null;
    stepPicker.value = STEP_PICKER.ARRANGE;
  };
  const nextToPickVariant = () => {
    stepPicker.value = STEP_PICKER.PICK_VARIANT;
  };

  const close = () => {
    emit('close');
    if (searchProductListReference.value) {
      searchProductListReference.value?.closeSearchSetting();
    }
    if (isPickProduct.value) {
      stepPicker.value = STEP_PICKER.ARRANGE;
    }
    if (props.pageType === 'POST_PURCHASE') {
      productSelected.value = null;
      stepPicker.value = STEP_PICKER.ARRANGE;
    }
  };

  const handleSave = () => {
    multiProductPickerReference?.value?.save();
    close();
  };

  const handleOpenAssign = () => {
    props.openAssignModal?.();
    close();
  };

  const openModal = () => {
    emit('openModal');
  };

  return {
    searchKeyword,
    searchProductListReference,
    multiProductPickerReference,
    optionList,
    titleBtn,
    onClickBtn,
    pickerTitle,
    stepPicker,
    offerTitle,
    productSelected,
    close,
    openModal,
    handleSelectTypePicker,
    setValueSelectedProducts,
    setValueSelectedCollection,
    handleOpenAssign,
    backToArrangeProduct,
    nextToPickVariant,
    handleSelectProductCurrent,
    handleSaveVariantSelected,
  };
};
