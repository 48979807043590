<script setup lang="ts">
import { SettingTitle } from '@gem/element-setting-ui';
import { GButton } from '@gem/uikit-v2';
import type { HeaderPickerTypeProps } from '../type';
import { STEP_PICKER } from '../constant';
import { computed } from 'vue';
import { ellipsisMiddleText } from '@gem/common';

const props = defineProps<HeaderPickerTypeProps>();

const enabledBackBtn = computed(() => {
  return (
    (props.stepPicker === STEP_PICKER.PICK_PRODUCT && props.productSrcType === 'PickProduct') ||
    props.stepPicker === STEP_PICKER.PICK_VARIANT
  );
});

const settingTittle = computed(() => {
  if (!props.productSelected) return props.pickerTitle;
  return ellipsisMiddleText(props.offerTitle || '', 20, 8);
});

const clickBack = () => {
  props.backToArrangeProduct();
  props.onBlurSearch();
};
</script>

<template>
  <div class="border-dark-300 flex h-[52px] items-center justify-between border-b pl-8 pr-10">
    <div class="flex h-full items-center gap-8 px-8">
      <GButton
        v-if="enabledBackBtn"
        only-icon="polaris-chevron-left"
        type="ghost"
        size="semi-medium"
        class="-ml-8"
        @click="clickBack" />
      <SettingTitle :label-with-lang="{ en: settingTittle }" variant="primary" />
    </div>
    <div class="flex h-full w-fit items-center justify-center gap-4">
      <GButton type="secondary" :loading="isLoading" size="normal" :disable="isLoading" @click.stop="refresh"
        >Sync
      </GButton>
      <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close()" />
    </div>
  </div>
</template>
