<template>
  <div v-show="statusDisplay" id="color-board-editor" class="gt-popup gt-popup-color gt-desktop">
    <div v-if="code_colors && code_colors.length" class="gt-color-set gt-text-color gt-selected-set">
      <span
        v-for="colorObject in code_colors"
        :key="colorObject.color_code"
        class="fr-command fr-select-color"
        :data-color="colorObject.color_code"
        :style="{ background: colorObject.color_code }"
        :class="[colorObject.status ? 'gt_active' : '']"
        @click="setColor(colorObject)">
        <span class="outline" />
        <span class="check">
          <svg
            v-if="color.color_code != 'clear_color'"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="svg-inline--fa fa-check fa-w-16">
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
              class />
          </svg>
          <svg
            v-else
            style="display: block"
            class="fr-svg"
            focusable="false"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15,10v8H9v-8H15 M14,4H9.9l-1,1H6v2h12V5h-3L14,4z M17,8H7v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V8z" />
          </svg>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorBoard',
  props: {
    colors: {
      type: [Array, Object],
      required: false,
      default: () => [
        {
          color_code: '#61BD6D',
          status: false,
        },
        {
          color_code: '#1ABC9C',
          status: false,
        },
        {
          color_code: '#54ACD2',
          status: false,
        },
        {
          color_code: '#2C82C9',
          status: false,
        },
        {
          color_code: '#9365B8',
          status: false,
        },
        {
          color_code: '#475577',
          status: false,
        },
        {
          color_code: '#CCCCCC',
          status: false,
        },
        {
          color_code: '#41A85F',
          status: false,
        },
        {
          color_code: '#00A885',
          status: false,
        },
        {
          color_code: '#3D8EB9',
          status: false,
        },
        {
          color_code: '#2969B0',
          status: false,
        },
        {
          color_code: '#553982',
          status: false,
        },
        {
          color_code: '#28324E',
          status: false,
        },
        {
          color_code: '#000001',
          status: false,
        },
        {
          color_code: '#F7DA64',
          status: false,
        },
        {
          color_code: '#FBA026',
          status: false,
        },
        {
          color_code: '#EB6B56',
          status: false,
        },
        {
          color_code: '#E25041',
          status: false,
        },
        {
          color_code: '#A38F84',
          status: false,
        },
        {
          color_code: '#EFEFEF',
          status: false,
        },
        {
          color_code: '#FFFFFF',
          status: false,
        },
        {
          color_code: '#FAC51C',
          status: false,
        },
        {
          color_code: '#F37934',
          status: false,
        },
        {
          color_code: '#D14841',
          status: false,
        },
        {
          color_code: '#B8312F',
          status: false,
        },
        {
          color_code: '#7C706B',
          status: false,
        },
        {
          color_code: '#D1D5D8',
          status: false,
        },
        {
          color_code: 'clear_color',
          status: false,
        },
      ],
    },
    color: String,
    classButton: String,
    type: String,
    positionPopupColor: Object,
  },
  emits: ['set-color-text-board', 'status-display-color-board'],
  data() {
    return {
      code_colors: [],
      statusDisplay: false,
    };
  },
  watch: {
    color(newVal) {
      this.code_colors = this.code_colors.map(function (item) {
        if (item.color_code == newVal.toUpperCase()) {
          item.status = true;
        } else {
          item.status = false;
        }
        return item;
      });
    },
    positionPopupColor(newVal) {
      document.getElementById('color-board-editor').style.left = 0 - newVal.left + 'px';
    },
  },
  created() {
    const _this = this;
    const colors = this.colors.map(function (color) {
      if (color.color_code == _this.$props.color) {
        color.status = true;
      }
      return color;
    });

    this.code_colors = colors;
  },
  methods: {
    setStatus(value) {
      this.statusDisplay = value;

      this.$emit('status-display-color-board', value);
      if (value == true) {
        const _thisFn = this;
        window.addEventListener('click', function () {
          const $parent = event.target.closest('.gt-popup-color');
          const $button = event.target.closest(_thisFn.$props.classButton);
          if (!$parent && !$button) {
            _thisFn.statusDisplay = false;
            _thisFn.$emit('status-display-color-board', false);
          }
        });
      }
    },
    setColor(color) {
      if (color.color_code != 'clear_color') {
        switch (this.type) {
          case 'text-color':
            if (!color.status) {
              this.code_colors.map(function (item) {
                if (item.color_code == color.color_code) {
                  item.status = true;
                } else {
                  item.status = false;
                }
                return item;
              });
              this.setStatus(false);
              this.$emit('set-color-text-board', color);
            }
            break;
          default:
            return false;
        }
      } else {
        this.code_colors.map(function (item) {
          item.status = false;
          return item;
        });
        this.setStatus(false);
        this.$emit('set-color-text-board', color.color_code);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gt-popup-color {
  // display: none;
  position: absolute;
  top: 30px;
  left: 0px;
  /* background: #fff; */
  /* padding: 5px; */
  color: #222222;
  background: #fff;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
  z-index: 2147483635;
  text-align: left;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;

  .gt-color-set {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    // padding-bottom: 0px;
    width: 175px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    .fr-select-color.gt_active {
      .check {
        svg {
          display: block !important;
        }
      }
    }
    .fr-select-color:hover {
      .outline {
        position: absolute;
        width: 93%;
        height: 93%;
        border: 1px solid;
      }
    }
    .fr-select-color {
      width: 25px;
      height: 25px;
      position: relative;
      .check {
        svg {
          color: #fff;
          width: 15px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          display: none;
        }
      }
    }
  }
}
</style>
