import type { GDropdownMenuItemProps } from '@gem/uikit-v2';
import type { ProductSource } from './type';

export const PRODUCT_PICKER_TYPE_OPTIONS: {
  id: string;
  value: ProductSource;
  label: string;
}[] = [
  {
    id: 'product',
    label: 'Products',
    value: 'PickProduct',
  },
  {
    id: 'collection',
    label: 'Collection',
    value: 'Collection',
  },
  {
    id: 'related',
    label: 'Related',
    value: 'RelatedProduct',
  },
];

export const sourcesRelatedTag: GDropdownMenuItemProps[] = [
  {
    title: 'Same collection',
    value: 'collection',
  },
  {
    title: 'Same vendor',
    value: 'vendor',
  },
  {
    title: 'Same tags',
    value: 'tags',
  },
];

export const LINK_GUIDELINE =
  'https://help.gempages.net/articles/change-url-handle#for-product-pages-and-collection-pages?utm_source=gempages&utm_medium=display&utm_content=in-app';

export const optionsVariantType = [
  { label: 'All variant', value: 'all', helpText: '' },
  { label: 'Single variant', value: 'single', helpText: '' },
];

export enum STEP_PICKER {
  ARRANGE = 'ARRANGE',
  PICK_PRODUCT = 'PICK_PRODUCT',
  PICK_VARIANT = 'PICK_VARIANT',
}
