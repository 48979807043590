<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { ShadowProps } from '../global-shadow/types';
import type { ColorProps } from '../global-color/types';
import AngleNoInput from '../AngleNoInput.vue';
import InputUnit from '../InputUnit.vue';
import { isColor } from '../../helpers/regex';
import ColorPickerModal from '../color-picker/ColorPickerModal.vue';

type Props = {
  id: string;
  value?: ShadowProps;
  isBoxShadow?: boolean;
  layout: string;
  emptyOnClear?: boolean;
  globalColors: ColorProps[];
  currentGlobalStyleColor: any | undefined;
};
const props = defineProps<Props>();
const val = ref<ShadowProps>(props.value ?? {});

const initialShadowValue: ShadowProps = {
  type: 'custom',
  angle: 37,
  distance: '4px',
  blur: '2px',
  color: 'rgba(0, 0, 0, 0.6)',
};

const extendValue = computed(() => {
  return props.value || initialShadowValue;
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'control-on-change', controlId: string, value?: any): void;
  (e: 'saveColor', newColorList: {}): void;
}>();

const customShadow = (shadowConfig: ShadowProps, type?: string) => {
  val.value = shadowConfig;
  if (type === 'controlOnChange') {
    emit('control-on-change', props.id, shadowConfig);
  } else {
    emit('controlChange', props.id, shadowConfig);
  }
};

const controlOnChange = (name?: string | number, value?: any) => {
  if (!name) return;
  onChangeData(
    {
      ...extendValue.value,
      [name]: value,
    },
    'controlOnChange',
  );
};

const onChangeData = (value: ShadowProps, type?: string) => {
  if (!value) return;

  changeData(value, type);
};

const changeData = (value: any, type?: string) => {
  const newValue = {
    ...props?.value,
    ...value,
    type: 'custom',
  };
  customShadow(newValue as ShadowProps, type);
};

const onChange = (name?: string | number, value?: any) => {
  if (!name) return;
  changeData({
    ...extendValue.value,
    [name]: value,
  });
};

const changeColor = (_id: string, color: string) => {
  if (isColor(color))
    changeData({
      ...extendValue.value,
      color,
    });
};

const isHorizontalLayout = computed(() => props.layout === 'horizontal');

watch(
  () => props.value,
  (newVal) => {
    if (newVal) val.value = newVal;
  },
);
</script>
<template>
  <div>
    <slot name="label"></slot>
    <div
      class="flex w-full"
      :class="{
        'flex-col items-center': isHorizontalLayout && !!props.isBoxShadow,
        'gap-x-24': !isHorizontalLayout || (isHorizontalLayout && !props.isBoxShadow),
      }">
      <div>
        <AngleNoInput
          id="angle"
          :value="extendValue?.angle"
          @control-on-change="controlOnChange"
          @control-change="onChange">
        </AngleNoInput>
      </div>
      <div>
        <div
          class="bg-dark-400 mb-8 flex cursor-pointer items-center justify-between rounded-xl"
          @click="() => $refs?.colorPickerModal?.onToggleColorModal()">
          <label class="text-12 text-text-dark-100 block cursor-pointer pl-8">Color</label>
          <ColorPickerModal
            id="color"
            ref="colorPickerModal"
            mode="solid"
            :value="extendValue?.color || initialShadowValue.color"
            :global-colors="globalColors"
            :current-global-style-color="currentGlobalStyleColor"
            @save-color="onSaveColor"
            @control-change="changeColor" />
        </div>
        <div>
          <InputUnit
            id="distance"
            inner-label="Distance"
            class="mb-8"
            :units="['px']"
            :value="extendValue?.distance"
            :max="100"
            :min="0"
            :control-change="onChange"
            :empty-on-clear="emptyOnClear"
            @control-on-change="controlOnChange" />
        </div>
        <div v-if="!!props.isBoxShadow">
          <InputUnit
            id="spread"
            inner-label="Size"
            class="mb-8"
            :max="100"
            :min="0"
            :units="['px']"
            :value="extendValue?.spread"
            :control-change="onChange"
            :empty-on-clear="emptyOnClear"
            @control-on-change="controlOnChange" />
        </div>
        <div>
          <InputUnit
            id="blur"
            inner-label="Blur"
            class="mb-8"
            :units="['px']"
            :max="100"
            :min="0"
            :value="extendValue?.blur"
            :control-change="onChange"
            :empty-on-clear="emptyOnClear"
            @control-on-change="controlOnChange" />
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
