import type { ShapeType } from './type';

import Shape1 from './shape/Shape1.vue';
import Shape2 from './shape/Shape2.vue';
import Shape3 from './shape/Shape3.vue';
import Shape4 from './shape/Shape4.vue';
// import Shape5 from './shape/Shape5.vue';
import Shape6 from './shape/Shape6.vue';
import Shape7 from './shape/Shape7.vue';
import Shape8 from './shape/Shape8.vue';
import ShapeIcon1 from './shape/ShapeIcon1.vue';
import ShapeIcon2 from './shape/ShapeIcon2.vue';
import ShapeIcon3 from './shape/ShapeIcon3.vue';
import ShapeIcon4 from './shape/ShapeIcon4.vue';
// import ShapeIcon5 from './shape/ShapeIcon5.vue';
import ShapeIcon6 from './shape/ShapeIcon6.vue';
import ShapeIcon7 from './shape/ShapeIcon7.vue';
import ShapeIcon8 from './shape/ShapeIcon8.vue';

export enum EShapeId {
  'square' = 'square',
  'vertical_rectangle' = 'vertical_rectangle',
  'horizontal_rectangle' = 'horizontal_rectangle',
  'ribbon' = 'ribbon',
  'star' = 'star',
  'ribbon_2' = 'ribbon_2',
  'ribbon_3' = 'ribbon_3',
  'triangle' = 'triangle',
}

export const shapes: ShapeType[] = [
  {
    id: EShapeId.horizontal_rectangle,
    name: 'Horizontal rectangle',
    image: Shape3,
    icon: ShapeIcon3,
    padding: {
      top: '8px',
      bottom: '8px',
      left: '12px',
      right: '12px',
    },
  },
  {
    id: EShapeId.vertical_rectangle,
    name: 'Vertical rectangle',
    image: Shape2,
    icon: ShapeIcon2,
    padding: {
      top: '12px',
      bottom: '12px',
      left: '8px',
      right: '8px',
    },
  },
  {
    id: EShapeId.square,
    name: 'Square',
    image: Shape1,
    icon: ShapeIcon1,
    ratio: 1,
    padding: {
      top: '0px',
      bottom: '0px',
      left: '12px',
      right: '12px',
    },
  },
  {
    id: EShapeId.ribbon,
    name: 'Ribbon',
    image: Shape4,
    icon: ShapeIcon4,
    ratio: 1,
    padding: {
      left: '8px',
      right: '8px',
      top: '0px',
      bottom: '0px',
    },
  },
  {
    id: EShapeId.star,
    name: 'Star 8 angles',
    image: Shape6,
    icon: ShapeIcon6,
    ratio: 1,
    padding: {
      left: '20px',
      right: '20px',
      top: '20px',
      bottom: '20px',
    },
  },
  {
    id: EShapeId.ribbon_2,
    name: 'Ribbon 2',
    image: Shape7,
    icon: ShapeIcon7,
    padding: {
      left: '10px',
      right: '27px',
      top: '8px',
      bottom: '8px',
    },
  },
  {
    id: EShapeId.ribbon_3,
    name: 'Ribbon 3',
    image: Shape8,
    icon: ShapeIcon8,
    padding: {
      left: '24px',
      right: '24px',
      top: '8px',
      bottom: '8px',
    },
  },
];

export const shapeMap = shapes.reduce((map, shape) => {
  map.set(shape.id, shape);
  return map;
}, new Map<string | undefined, ShapeType>([[undefined, shapes[shapes.length - 1]]]));
