<script lang="ts" setup>
import { GReorder } from '@gem/uikit';
import { GButton } from '@gem/uikit-v2';
import { computed, watch } from 'vue';
import BannerWarningSelect from './BannerWarningSelect.vue';
import { replaceImageToSize } from '../../../../hook/useResizeImage';
import type { ArrangeProductsTypeProps } from '../type';
import type { Product } from '../../types';
import { tooltipIconStatusContent } from '../helper';
import ArchivedStatus from '../../../../icons/ArchivedStatus.vue';
import DraftStatus from '../../../../icons/DraftStatus.vue';
import SkeletonProductList from './SkeletonProductList.vue';

const props = defineProps<ArrangeProductsTypeProps>();
const emit = defineEmits<{
  (e: 'selectVariant', value: Product): void;
}>();

const productListArrange = computed(() => props.selectedProductList ?? []);

const handleSelectProductVariant = (item: Product) => {
  emit('selectVariant', item);
};

const isPostPurchasePage = computed(() => props.pageType === 'POST_PURCHASE');

const checkShowVariantTitle = (item: Product) => {
  return isPostPurchasePage.value && item.variants.totalCount > 1;
};

watch(productListArrange, () => {
  if (productListArrange?.value.length === 1) return;

  props.closeAlert();
});

const showClass = computed(() => ({
  classWarning: !!props.contentAlertRange && !isPostPurchasePage.value,
  classCommon: !props.contentAlertRange && !isPostPurchasePage.value,
  classPostPurchase: isPostPurchasePage.value,
}));

const isShowNoProductArrange = computed(() => !productListArrange?.value.length);
</script>
<template>
  <div
    v-if="isShowNoProductArrange"
    class="flex h-[336px] max-h-[calc(100vh-258px)] w-full flex-col items-center justify-center">
    <img class="h-[80px] w-[80px] rounded-xl" src="../../../images/searchEmpty.png" alt="collections" />
    <div class="text-12 font-regular text-text-dark-300 text-center leading-5">
      No product selected.<br />Pick products then arrange.
    </div>
  </div>
  <div v-else>
    <BannerWarningSelect :content="contentAlertRange" :close-alert="closeAlert" />
    <perfect-scrollbar
      :class="{
        'max-height-list-arrange-warning !h-[282px]': showClass.classWarning,
        'max-height-list-arrange !h-[323px]': showClass.classCommon,
        'max-height-list-arrange-pp !h-[395px]': showClass.classPostPurchase,
      }">
      <SkeletonProductList v-if="isLoading" :items-length="6" height="323px" />
      <div v-else class="px-16">
        <GReorder
          :items="productListArrange"
          @reOrder="handleSubmitReorder"
          itemGap="0"
          :scroll="true"
          :scroll-speed="7">
          <template #default="{ item }">
            <div
              class="hover:bg-dark-300 group relative flex cursor-ns-resize items-center gap-8 overflow-hidden rounded-[12px] p-8 pl-4">
              <div class="h-20 w-20 text-white">
                <GBaseIcon name="reorder" width="20" height="20" />
              </div>
              <div
                class="h-[40px] w-[40px] flex-shrink-0 rounded-xl bg-transparent"
                :class="{
                  'flex items-center justify-center': !item.image,
                }">
                <img
                  v-if="item.image"
                  class="h-full w-full rounded-xl object-cover"
                  :src="replaceImageToSize(item?.image ?? '', '120x120') ?? item.image"
                  alt="collections" />
                <img v-else class="h-full w-full rounded-xl" src="../../../images/no-product.png" alt="collections" />
              </div>
              <div
                class="text-12 text-text-dark-500 font-regular w-full truncate whitespace-pre-wrap break-words leading-5">
                <p
                  :class="{
                    'line-clamp-1': isPostPurchasePage,
                    'line-clamp-2': !isPostPurchasePage,
                  }">
                  {{ item?.title }}
                </p>
                <p v-if="checkShowVariantTitle(item)" class="text-text-light-300 line-clamp-1">
                  {{ variantSelected?.[item?.id]?.variantTitle ?? 'All variant' }}
                </p>
              </div>
              <div v-if="item.status?.toLowerCase() !== 'active'" class="flex items-center">
                <g-tooltip
                  placement="top"
                  wrapper-class="w-full"
                  content-class="!whitespace-normal"
                  :is-teleport="true">
                  <DraftStatus v-if="item?.status?.toLowerCase() === 'draft'" />
                  <ArchivedStatus v-else />
                  <template #content>
                    <div class="text-text-dark-500 font-regular text-12 leading-5">
                      {{ tooltipIconStatusContent(item?.status?.toLowerCase()) }}
                    </div>
                  </template>
                </g-tooltip>
              </div>
              <div
                class="z-5 absolute right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
                :style="{
                  background:
                    'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
                }">
                <g-tooltip
                  placement="top"
                  wrapper-class="w-full"
                  content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4"
                  :is-teleport="true">
                  <GButton
                    size="small"
                    type="ghost"
                    only-icon="polaris-x"
                    @click.stop="() => handleUnselected(item?.id)" />
                  <template #content>
                    <div class="text-text-dark-500 font-regular text-12 leading-5">Unselect</div>
                  </template>
                </g-tooltip>
                <g-tooltip
                  placement="top"
                  wrapper-class="w-full"
                  content-class="!shadow-[0px_8px_16px_2px_rgba(0,0,0,0.24)] py-4"
                  v-if="isPostPurchasePage"
                  :is-teleport="true">
                  <GButton
                    v-if="item.variants.totalCount > 1"
                    only-icon="polaris-chevron-right"
                    type="ghost"
                    size="small"
                    @click.stop="handleSelectProductVariant(item)" />
                  <template #content>
                    <div class="text-text-dark-500 font-regular text-12 leading-5">Select variant</div>
                  </template>
                </g-tooltip>
              </div>
            </div>
          </template>
        </GReorder>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<style lang="css" scoped>
@media (max-height: 580px) {
  .max-height-list-arrange {
    max-height: calc(100vh - 258px) !important;
  }
  .max-height-list-arrange-pp {
    max-height: calc(100vh - 214px) !important;
  }

  .max-height-list-arrange-warning {
    max-height: calc(100vh - 326px) !important;
  }
}
.max-height-list-arrange {
  max-height: 323px;
}
</style>
