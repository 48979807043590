import type { ProductOption } from '../../../type/common';
import type { GraphQLConnection, ProductVariant } from '../types';
import type { Product } from '../types';

export const flattenConnection = <T>(connection?: GraphQLConnection<T>) => {
  return connection?.edges?.map((edge) => edge.node) ?? [];
};

export const getDefaultVariantSelected = (item: Product) => {
  let variant = {};
  const variantsFlat = flattenConnection(item.variants) as ProductVariant[];
  const variants: ProductVariant[] = variantsFlat?.sort(
    (a: ProductVariant, b: ProductVariant) => a.position - b.position,
  );
  const variantFirst = variants[0];
  const variantFirstInStock = variants?.find((el) => checkInStock(el));
  const initialVariant = variantFirstInStock ?? variantFirst;
  const values = initialVariant?.title?.split(' / ');
  item?.options?.map((option, idx) => {
    variant = {
      ...variant,
      [option?.name ?? '']: values[idx],
    };
  });

  return {
    variant,
    variantId: initialVariant?.id,
    variantTitle: initialVariant?.title,
  };
};

const checkInStock = (variant?: ProductVariant) => {
  if (!variant) return false;
  return (
    (variant?.inventoryQuantity && variant.inventoryQuantity > 0) ||
    !variant?.manageInventory ||
    variant.inventoryPolicy === 'CONTINUE'
  );
};

export const getOptions = (
  option?: ProductOption,
  optionSelected?: Record<string, string>,
  variants?: ProductVariant[],
) => {
  const { values, name } = option || {};
  const temp = values?.map((el) => {
    const optionCurrent: Record<string, string> = {
      ...optionSelected,
    };
    if (name && el.label) optionCurrent[name] = el.label;
    const title = Object.values(optionCurrent).join(' / ');
    const variant = variants?.find((el) => el.title === title);
    return {
      value: el.label,
      id: el.label,
      name: el.label,
      disabled: !checkInStock(variant),
    };
  });
  return temp;
};
